.documentation {
    width: 100%;
    height: 100%;
    display: flex;
    font-family: 'Roboto', Helvetica, Arial, sans-serif;

    .fab-button {
        &-container {
            position: fixed;
            bottom: 40px;
            right: 40px;
            width: auto;
            height: auto;

            &.pending {
                cursor: progress !important;
                span {
                    cursor: progress !important;
                }
            }
        }

        background-color: #1976d2;
        svg {
            color: #fff;
        }
        &:hover {
            background-color: rgb(17, 82, 147);
        }
    }

    &__nav {
        &-container {
            // z-index: 5;
            min-width: 300px;
            width: 300px;
            height: calc(100vh - 64px);
            overflow-x: hidden;
            overflow-y: auto;
            padding-right: -5px;

            border-right: 1px solid rgba(0, 0, 0, 0.12);
            box-sizing: border-box;
            background-color: white;
            transition: all 0.2s ease-out;

            /* width */
            &::-webkit-scrollbar {
                width: 7px;
                height: 7px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background: transparent;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: rgba(136, 136, 136, 0.25);
                border-radius: 5px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: rgba(136, 136, 136, 1);
            }

            @media screen and (max-width: 1024px) {
                position: absolute;
                transform: translateX(-300px);
                &.active {
                    transform: translateX(0px);
                }
            }
        }

        .documentation__nav__collapse {
            &-link {
                text-decoration: none;
                color: rgba(0, 0, 0, 0.8);
            }
            &-item {
                padding: 2px 15px 2px 35px;
                &:hover {
                    text-decoration: none;
                }
            }
            &-text {
                margin-top: 4px;
                margin-bottom: 4px;
                font-size: 14px;
                line-height: 21px;
                font-weight: 400;
                letter-spacing: 0.01em;
                text-decoration: none;
            }
        }
    }

    &__section {
        &-container {
            flex-grow: 1;
            height: 100%;
            width: 100%;
            display: flex;
            overflow-y: auto;
            overflow-x: hidden;
            /* width */
            &::-webkit-scrollbar {
                width: 7px;
                height: 7px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background: transparent;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: rgba(136, 136, 136, 0.25);
                border-radius: 5px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: rgba(136, 136, 136, 1);
            }
        }

        width: calc(100vw - 300px);
        height: fit-content;

        @media screen and (max-width: 1024px) {
            width: calc(100vw);
        }
    }
}

.doc-list {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    max-width: 940px;
    padding: 30px 40px;
    margin: 0 auto;

    @media screen and (max-width: 1024px) {
        padding: 30px 20px;
    }
    @media screen and (max-width: 768px) {
        padding: 15px 10px;
    }
    &__group {
        display: flex;
        flex-flow: column nowrap;
        margin-bottom: 20px;
        font-family: 'Roboto', Helvetica, Arial, sans-serif;

        &__title {
            font-size: 32px;
            font-weight: 400;
            line-height: 1.2;
        }

        &__items {
            display: grid;
            grid-template-columns: 50% 50%;
            padding-left: 20px;

            @media screen and (max-width: 768px) {
                grid-template-columns: 100%;
            }
        }

        &__item {
            margin-top: 5px;

            a {
                display: flex;
                font-size: 16px;
                line-height: 1.2;
                text-decoration: none;
                padding: 5px 10px;
                color: rgb(0, 101, 179);

                &:hover {
                    text-decoration: underline;
                }

                &:visited {
                    color: rgb(102, 0, 153);
                }
            }
        }
    }
}

.doc {
    &-container {
        display: flex;
        font-family: 'Roboto', Helvetica, Arial, sans-serif;
    }

    &-wrapper {
        width: calc(100% - 200px);
        display: flex;
        justify-content: center;
        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }
    width: 100%;
    max-width: 940px;
    padding: 30px 40px;

    @media screen and (max-width: 1024px) {
        padding: 30px 20px;
    }
    @media screen and (max-width: 768px) {
        padding: 15px 10px;
    }

    &__header {
        display: flex;
        flex-flow: column nowrap;

        &__name {
            &-container {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            font-size: 36px;
            font-weight: 500;
            line-height: 1.2;
            max-width: 100%;

            /* elipsis */
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @media screen and (max-width: 768px) {
                font-size: 30px;
            }
            @media screen and (max-width: 425px) {
                font-size: 24px;
            }

            .doc-type {
                font-weight: 400;
                color: rgba(0, 0, 0, 0.8);
                padding-right: 15px;
            }
        }

        &__description {
            margin-top: 20px;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.5;
            letter-spacing: 0.02em;
            white-space: pre-wrap;
            color: rgba(0, 0, 0, 0.87);
        }
    }
    &__body-param {
        margin-top: 20px;
        &__name {
            margin-bottom: 20px;
            font-size: 24px;
            font-weight: 400;
            line-height: 1.2;
            color: rgba(0, 0, 0, 0.87);
        }
        &__table {
            display: flex;
            flex-flow: column nowrap;
            width: 100%;
            overflow-x: auto;
            /* width */
            &::-webkit-scrollbar {
                height: 5px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background: transparent;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: rgba(136, 136, 136, 0.5);
                border-radius: 5px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: rgba(136, 136, 136, 1);
            }

            .table-header {
                display: flex;
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                width: fit-content;
                min-width: 100%;

                &__column {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 5px 10px;
                    font-size: 16px;
                    font-weight: 500;
                    text-transform: uppercase;
                    line-height: 1.2;

                    border-right: 1px solid rgba(0, 0, 0, 0.12);

                    &:last-of-type {
                        border-right: none;
                    }
                    &:nth-of-type(1) {
                        flex: 2 1;
                        min-width: 200px;
                    }
                    &:nth-of-type(2) {
                        flex: 1 1;
                        min-width: 120px;
                    }
                    &:nth-of-type(3) {
                        flex: 3 1;
                        min-width: 200px;
                    }
                }
            }

            .table-body {
                display: flex;
                flex-flow: column nowrap;
                width: fit-content;
                min-width: 100%;

                &__row {
                    display: flex;
                    &:nth-of-type(odd) {
                        background-color: rgba(154, 172, 231, 0.1);
                    }
                }

                &__column {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 5px 10px;
                    font-size: 16px;
                    text-align: center;
                    line-height: 1.2;
                    color: rgba(0, 0, 0, 0.87);
                    border-right: 1px solid rgba(0, 0, 0, 0.12);

                    &:last-of-type {
                        border-right: none;
                    }

                    &:nth-of-type(1) {
                        flex: 2 1;
                        min-width: 200px;
                    }
                    &:nth-of-type(2) {
                        flex: 1 1;
                        min-width: 120px;
                    }
                    &:nth-of-type(3) {
                        flex: 3 1;
                        min-width: 200px;
                        justify-content: left;
                        white-space: pre-wrap;
                        text-align: left;
                    }
                }
            }
        }
    }
    &__syntax {
        margin-top: 20px;
        display: flex;
        flex-flow: column nowrap;
        &__topline {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
        }

        &__name {
            margin-bottom: 20px;
            font-size: 24px;
            font-weight: 400;
            line-height: 1.2;
            color: rgba(0, 0, 0, 0.87);
        }
        &__example {
            &--container {
                display: flex;
                flex-flow: column nowrap;
                border: 1px solid rgba(0, 0, 0, 0.12);
                border-radius: 4px;
            }
            &--buttons {
                display: flex;
                padding: 2px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                background-color: rgba(200, 215, 255, 0.05);
                & > span {
                    flex: 1;
                }
            }
            &--string {
                padding: 10px 15px;
                background-color: rgba(200, 215, 255, 0.025);

                font-family: monospace;
                font-size: 14px;
                font-weight: 400;
                line-height: 1.5;
            }
        }
    }
    &__history {
        margin-top: 20px;
        &__name {
            margin-bottom: 20px;
            font-size: 24px;
            font-weight: 400;
            line-height: 1.2;
            color: rgba(0, 0, 0, 0.87);
        }

        &__items {
            display: flex;
            flex-flow: column nowrap;
        }

        &__item {
            padding: 10px 15px;
            margin-bottom: 20px;
            font-size: 16px;
            line-height: 1.5;
            color: rgba(0, 0, 0, 0.87);
            background-color: rgba(154, 172, 231, 0.1);
            border-radius: 8px;
        }
    }

    /* CONTENT */

    &__content {
        &-container {
            position: relative;
            width: 195px;
            padding-top: 30px;
            @media screen and (max-width: 768px) {
                display: none;
            }
        }
        width: 195px;
        position: fixed;
    }
}

.content {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    &__name {
        padding-left: 8px;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5;
        letter-spacing: 0.01em;
        color: rgba(0, 0, 0, 0.87);
    }
    &__list {
        display: flex;
        flex-flow: column nowrap;

        a {
            display: block;
            padding: 4px 0px 4px 5px;
            font-size: 14px;
            line-height: 1.5;
            box-sizing: border-box;
            text-decoration: none;
            border-left: 3px solid transparent;
            color: rgba(0, 0, 0, 0.54);

            &.active {
                color: rgba(0, 0, 0, 0.87);
                border-left-color: #e0e0e0;
            }
            &:hover {
                border-left-color: #eeeeee;
            }

            &:active {
                border-left-color: #e0e0e0;
            }
        }
    }
}

.elipsis-wrapper {
    /* elipsis */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
