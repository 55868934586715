.search-list {
    position: absolute;
    margin-top: 2px;
    width: 100%;
    height: fit-content;
    max-height: 300px;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1), 0px 2px 2px rgba(0, 0, 0, 0.25);
    overflow-x: hidden;
    overflow-y: auto;

    /* width */
    &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: rgba(241, 241, 241, 0.2);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: rgba(136, 136, 136, 0.5);
        border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: rgba(136, 136, 136, 1);
    }

    &__empty {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 15px;
        font-size: 16px;
        line-height: 1.2;
        color: rgba(0, 0, 0, 0.85);
    }

    &__item {
        display: flex;
        flex-flow: column nowrap;
        color: rgba(0, 0, 0, 0.85);
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        padding: 10px 5px;
        text-decoration: none;
        &:hover {
            background-color: rgba(0, 0, 0, 0.05);
        }

        &__name {
            display: flex;
            flex-flow: row wrap;
            font-size: 16px;
            line-height: 1.2;

            .type {
                font-weight: 400;
                padding-right: 10px;
            }

            .name {
                font-weight: 500;
            }
        }

        &__description {
            margin-top: 10px;
            height: 36px;
            font-size: 12px;
            line-height: 1;
            font-weight: 300;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
}
