.main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.project-link {
    text-decoration: none;
    color: #1662ad;
}
.projectslist {
    &-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 40px;
        width: 100%;
        height: 100%;

        @media screen and (max-width: 768px) {
            padding: 10px 15px;
        }
    }
    display: flex;
    flex-flow: column nowrap;
    max-width: 940px;
    width: 100%;
    height: 100%;
    padding: 20px 40px;
    background-color: #fff;
    border-radius: 4px;
    // border: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1), 4px 4px 4px rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 768px) {
        padding: 10px 15px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h1 {
            font-size: 24px;
            line-height: 1.2;
            font-weight: 500;
            color: rgb(25, 118, 210);
        }
    }
}
