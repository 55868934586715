.page-404 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__image {
        max-height: 100%;
        max-width: 100%;
        &-container {
            max-width: 600px;
            max-height: 100%;
            width: 100%;
            height: 100%;
            padding: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
