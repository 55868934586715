@import './reset';
@import './helpers/variables';
@import './helpers/mixins';
@import './fonts';

body {
    font-family: 'Roboto', Helvetica, Arial, sans-serif;
    font-style: normal;
}

.app {
    display: flex;
    flex-flow: column nowrap;
    width: 100vw;
    height: 100vh;

    &__body {
        flex: 1;
        overflow-x: hidden;
        overflow-y: auto;
        /* width */
        &::-webkit-scrollbar {
            width: 7px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: rgba(241, 241, 241, 0.2);
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 5px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }
}

.Loader {
    &--container {
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
