.table-list {
    .table {
        display: flex;
        flex-flow: column nowrap;
        margin-top: 10px;
        max-height: calc(100% - 44px);
        max-width: 100%;
        overflow-x: auto;
        &::-webkit-scrollbar {
            height: 5px;
            width: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgba(136, 136, 136, 0.5);
            border-radius: 5px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: rgba(136, 136, 136, 1);
        }

        &-head {
            flex: 0 0;
            display: flex;
            flex-flow: row nowrap;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            min-width: fit-content;

            &__column {
                flex: 1 1;
                display: flex;
                align-items: center;
                padding: 5px 10px;
                font-size: 14px;
                font-weight: 600;
                text-transform: uppercase;
                line-height: 1.2;
                color: rgba(0, 0, 0, 0.8);

                &:first-of-type {
                    border-right: 1px solid rgba(0, 0, 0, 0.12);
                }

                &.button {
                    justify-content: center;
                    flex: 0 0;
                    min-width: 60px;
                }
                &:nth-of-type(1) {
                    min-width: 180px;
                    margin-right: 5px;
                }
                &:nth-of-type(2) {
                    min-width: 80px;
                }
            }
        }
        &-body {
            flex: 1;
            overflow-y: scroll;
            min-width: fit-content;

            /* width */
            &::-webkit-scrollbar {
                height: 5px;
                width: 5px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background: transparent;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: rgba(136, 136, 136, 0.5);
                border-radius: 5px;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: rgba(136, 136, 136, 1);
            }

            &__row {
                display: flex;
                flex-flow: row nowrap;
                &:nth-of-type(even) {
                    background-color: rgba(154, 172, 231, 0.1);
                }
            }
            &__column {
                flex: 1 1;
                display: flex;
                align-items: center;
                padding: 5px 10px;
                font-size: 14px;
                line-height: 1.2;
                color: rgba(0, 0, 0, 0.87);

                &:first-of-type {
                    border-right: 1px solid rgba(0, 0, 0, 0.12);
                }

                &:nth-of-type(1) {
                    min-width: 180px;
                }
                &:nth-of-type(2) {
                    min-width: 80px;
                }

                &.button {
                    justify-content: center;
                    flex: 0 0;
                    min-width: 60px;
                }
            }
        }
    }
}
